import React from 'react'
import { graphql } from 'gatsby'

import { Contact as ContactPageComponent } from '../components/pages'

const Contact: React.FC<Props> = ({ data, location }) => {
  const contactNode: ContactNode = data.contact.nodes[0]

  return (
    <ContactPageComponent
      title={contactNode.title}
      gatsbyImageData={contactNode.image?.gatsbyImageData}
      content={contactNode.content.content}
      location={location}
    />
  )
}

export default Contact

export const query = graphql`
  query ContactQuery {
    contact: allContentfulContact(limit: 1, sort: { fields: createdAt, order: DESC }) {
      nodes {
        title
        content {
          content
        }
        image {
          gatsbyImageData(layout: CONSTRAINED, height: 1440)
        }
      }
    }
  }
`

interface Props {
  data: {
    contact: {
      nodes: ContactNode[]
    }
  }
  location: any
}

interface ContactNode {
  title: string
  content: {
    content: string
  }
  image: {
    gatsbyImageData: any
  }
}
